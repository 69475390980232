export const colorPalette = [
  "#0B2033",
  "#0171E6",
  "#34A853",
  "#FF6300",
  "#F9C846",
  "#E04E5D",
  "#B6D87F",
  "#7EC1C1",
  "#9C27B0",
];

export const resumeValues = {
  total_purchased: {
    title: "Monto total comprado",
    description: "Total de dinero gastado.",
  },
  buyed_vs_efficient: {
    title: "Monto comprado Vs. eficiente",
    description: "Diferencia entre el monto total comprado y eficiente.",
  },
  medicinal_total: {
    title: "Monto total medicinal",
    description: "Total de dinero gastado en medicinales.",
  },
  non_medicinal_total: {
    title: "Monto total no medicinal",
    description: "Total de dinero gastado en no medicinales.",
  },
  orders: {
    title: "Órdenes realizadas",
    description: "Total de órdenes de compra realizadas.",
  },
  prices_variation: {
    title: "Dispersión de precios",
    description:
      "Es el coeficiente de variación calculado sobre todas las ordenes de compra efectuadas.",
  },
};

export const analisisValues = {
  real_and_orders_purchase_per_dm: {
    title: "Monto comprado y órdenes por droguería",
    description: "Monto gastado y cantidad de O.C por droguería.",
  },

  real_and_orders_purchase_per_pos: {
    title: "Monto comprado y órdenes por punto de venta",
    description: "Monto gastado y cantidad de O.C por punto de venta.",
  },
};

export const formatDataForMoneySpentChart = (data) => {
  const resultObj = {};
  data.forEach((obj) => {
    for (const key in obj) {
      const keyNumber = key;
      if (!resultObj[keyNumber]) {
        resultObj[keyNumber] = [];
      }
      resultObj[keyNumber].push(obj[key]);
    }
  });
  const output = Object.entries(resultObj).map(([name, value]) => ({
    name: name,
    data: value,
  }));
  return output;
};
export const formatDateToYYYYMMDD = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}${month}${day}`;
};
