import axios from "axios";

import { useUserAuth } from "@extendeal/vendors-design-system";

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_USER_PROFILE_API}/api`,
});

const useUserProfileApi = () => {
  const { token, isAuthenticated } = useUserAuth();

  const authorizationHeaders = { Authorization: `Bearer ${token}` };

  const getUserLocation = async (): Promise<
    GetUserProfile.ApiResponse | undefined
  > => {
    try {
      const { data } = await instance.get<
        GetUserProfile.ApiResponse | undefined
      >("/users/me", {
        headers: authorizationHeaders,
      });
      return data;
    } catch (error) {
      console.error(error);
    }
  };
  return {
    getUserLocation,
  };
};
export default useUserProfileApi;
