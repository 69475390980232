import Exporting from "highcharts/modules/exporting";
import offlineExportingModule from "highcharts/modules/offline-exporting";
import Highcharts from "highcharts";
import exportDataModule from "highcharts/modules/export-data";
import { useEffect } from "react";
import { LanguageUtils } from ".";
import { useTranslation } from "../hooks";
const getLocaleConfig = (lang: string) => {
  const locales = {
    "es-AR": { decimalPoint: ",", thousandsSep: "." }, // Argentina
    "es-MX": { decimalPoint: ".", thousandsSep: "," }, // México
    "es-CO": { decimalPoint: ",", thousandsSep: "." }, // Colombia
    "pt-BR": { decimalPoint: ",", thousandsSep: "." }, // Brasil
    "en-US": { decimalPoint: ".", thousandsSep: "," }, // Estados Unidos
    "es-ES": { decimalPoint: ",", thousandsSep: "." }, // España
  };

  return locales[lang] || { decimalPoint: ".", thousandsSep: "," };
};
const browserLanguage = navigator.language;

const localeConfig = getLocaleConfig(browserLanguage);

if (typeof Exporting === "function") {
  Exporting(Highcharts);
  offlineExportingModule(Highcharts);
  exportDataModule(Highcharts);
}
const HighchartsLocalizationConfiguration = (
  language: LanguageUtils.SupportedLanguages,
  t: any
) => {
  useEffect(() => {
    const localeConfig = LanguageUtils.getLocaleConfigForHighcharts(language);

    Highcharts.setOptions({
      lang: {
        viewFullscreen: t("ver-en-pantalla-completa"),
        printChart: t("imprimir-grafico"),
        downloadPNG: t("descargar-imagen-png"),
        downloadJPEG: t("descargar-imagen-jpeg"),
        downloadPDF: t("descargar-archivo-pdf"),
        downloadSVG: t("descargar-imagen-svg"),
        downloadCSV: t("descargar-csv"),
        downloadXLS: t("descargar-archivo-xls"),
        viewData: t("ver-tabla-de-datos"),
        decimalPoint: localeConfig.decimalPoint,
        thousandsSep: localeConfig.thousandsSep,
      },
    });
  }, [language]);
};

export default HighchartsLocalizationConfiguration;
