import { useCallback, useEffect, useState } from "react";
import { styled } from "styled-components";
import { useDataProvider } from "../../../context/provider";
import SortableList, { SortableItem, SortableKnob } from "react-easy-sort";
import { arrayMoveImmutable } from "array-move";
import { useReorderProvider } from "../../../context/ReorderContext/provider";

import { transparentize } from "polished";
import {
  Box,
  Button,
  Icon,
  Skeleton,
  Stack,
  Tooltip,
  useGoogleAnalytics,
  Whisper,
} from "@extendeal/vendors-design-system";
import { NumberUtils } from "../../../utils";
import SummaryWidgetSkeleton from "./organisms/SummaryWidgetSkeleton";
import { useTranslation } from "../../../hooks";
import { GTags } from "../../../models/gtags";

const Resume = () => {
  const { state, isLoading } = useDataProvider();
  const { t } = useTranslation();
  const { trackEvent } = useGoogleAnalytics();

  const resumeValues = {
    total_purchased: {
      title: t("monto-total-comprado"),
      description: t("total-de-dinero-gastado"),
      icon: (
        <Box borderRadius={"md"} backgroundColor={"secondary-50"} p={"1"}>
          <Icon as="sales" type="outline" color="neutral-800" />
        </Box>
      ),
      content: (value?: number): string => {
        if (!value) return "0";
        return NumberUtils.formatWithSuffix(value);
      },
    },
    buyed_vs_efficient: {
      title: t("monto-comprado-vs-eficiente"),
      description: t("diferencia-entre-el-monto-total-comprado-y-eficiente"),
      icon: (
        <Box borderRadius={"md"} backgroundColor={"secondary-50"} p={"1"}>
          <Icon as="sales" type="outline" color="neutral-800" />
        </Box>
      ),
      content: (value?: number): string => {
        if (!value) return "0";
        return NumberUtils.formatWithSuffix(value);
      },
    },
    medicinal_total: {
      title: t("monto-total-medicinal"),
      description: t("total-de-dinero-gastado-en-medicinales"),
      icon: (
        <Box borderRadius={"md"} backgroundColor={"secondary-50"} p={"1"}>
          <Icon as="sales" type="outline" color="neutral-800" />
        </Box>
      ),
      content: (value?: number): string => {
        if (!value) return "0";
        return NumberUtils.formatWithSuffix(value);
      },
    },
    non_medicinal_total: {
      title: t("monto-total-no-medicinal"),
      description: t("total-de-dinero-gastado-en-no-medicinales"),
      icon: (
        <Box borderRadius={"md"} backgroundColor={"secondary-50"} p={"1"}>
          <Icon as="sales" type="outline" color="neutral-800" />
        </Box>
      ),
      content: (value?: number): string => {
        if (!value) return "0";
        return NumberUtils.formatWithSuffix(value);
      },
    },
    orders: {
      title: t("ordenes-realizadas"),
      description: t("total-de-ordenes-de-compra-realizadas"),

      icon: (
        <Box borderRadius={"md"} backgroundColor={"secondary-50"} p={"1"}>
          <Icon as="receipt" type="outline" color="neutral-800" />
        </Box>
      ),
      content: (value?: number): string => {
        if (!value) return "0";
        return NumberUtils.formatWithSuffix(value);
      },
    },
    prices_variation: {
      title: t("dispersion-de-precios"),
      description: t(
        "muestra-que-tan-diferentes-son-los-precios-de-las-compras-hasta-10-es-bajo-precios-similares-10-a-20-medio-20-alto-precios-muy-diferentes"
      ),
      icon: (
        <Box borderRadius={"md"} backgroundColor={"secondary-50"} p={"1"}>
          <Icon as="percent" type="outline" color="neutral-800" />
        </Box>
      ),
      content: (value: number): string => {
        return value + "%";
      },
    },
  };
  const {
    items,
    updateItems,
    startReorder,
    isReordering,
    visibility,
    toggleWidgetVisibility,
  } = useReorderProvider();

  const resumeKeys = state.resume ? Object.keys(state?.resume) : null;

  useEffect(() => {
    if (!resumeKeys) return;
    startReorder("resumen", resumeKeys);
  }, []);

  const onSortEnd = useCallback(
    (oldIndex, newIndex) => {
      const result = arrayMoveImmutable(items.resumen, oldIndex, newIndex);
      updateItems("resumen", result);
    },
    [items, updateItems]
  );

  return (
    <Container
      backgroundColor={
        isReordering ? "semantic-information-100" : "secondary-25"
      }
      // reorderstate={isReordering}
      // isvisible={isReordering || visibility.resumen}
    >
      <Header>
        <TitleContainer>
          {isReordering && (
            <SortableKnob>
              <div
                onClick={() => {
                  trackEvent(GTags.ReorderTrackingEvents["Reorder-summary"], {
                    name: "summary",
                  });
                }}
              >
                <Icon as="drag" color="neutral-600" />
              </div>
            </SortableKnob>
          )}
          <Title>{t("resumen")} </Title>
        </TitleContainer>
        <VisibilityContainer>
          {" "}
          {isReordering ? (
            <Box>
              {" "}
              {visibility.resumen ? (
                <Button
                  backgroundColor={"semantic-information-100"}
                  p={"1"}
                  onClick={() => {
                    trackEvent(
                      GTags.ReorderTrackingEvents["Visibility-summary"]
                    );
                    toggleWidgetVisibility("resumen");
                  }}
                >
                  <Icon as="eye" type="outline" />
                </Button>
              ) : (
                <Button
                  backgroundColor={"semantic-information-100"}
                  p={"1"}
                  onClick={() => toggleWidgetVisibility("resumen")}
                >
                  <Icon as="eyeOff" />
                </Button>
              )}
            </Box>
          ) : null}
        </VisibilityContainer>
      </Header>
      <Line></Line>
      <Sortable
        onSortEnd={onSortEnd}
        allowDrag={isReordering}
        style={{ width: "100%", display: "flex" }}
      >
        <WidgetContainer>
          {items.resumen?.map((key) => {
            return (
              <Box
                key={key}
                backgroundColor={
                  isReordering || visibility[key]
                    ? "semantic-information-50"
                    : "secondary-25"
                }
                borderRadius="md"
              >
                <SortableItem key={key}>
                  {isLoading ? (
                    <Widget isvisible={isReordering || visibility[key]}>
                      <SummaryWidgetSkeleton />
                    </Widget>
                  ) : (
                    <Widget isvisible={isReordering || visibility[key]}>
                      <Box>
                        <Stack
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <WidgetTitleContainer>
                            {isReordering ? (
                              <SortableKnob>
                                <div
                                  onClick={() => {
                                    trackEvent(
                                      GTags.ReorderTrackingEvents[
                                        "Reorder-summary"
                                      ],
                                      {
                                        name: resumeValues[key]?.title,
                                      }
                                    );
                                  }}
                                >
                                  <Icon as="drag" color="neutral-600" />
                                </div>
                              </SortableKnob>
                            ) : null}

                            {resumeValues[key]?.icon}
                            <WidgetTitle>
                              {resumeValues[key]?.title}
                            </WidgetTitle>
                          </WidgetTitleContainer>
                          {isReordering && (
                            <Box>
                              {" "}
                              {visibility[key] ? (
                                <Button
                                  backgroundColor={"neutral-white"}
                                  p={"1"}
                                  onClick={() => {
                                    trackEvent(
                                      GTags.ReorderTrackingEvents[
                                        "Visibility-summary"
                                      ],
                                      {
                                        name: resumeValues[key]?.title,
                                      }
                                    );
                                    toggleWidgetVisibility(key);
                                  }}
                                >
                                  <Icon as="eye" type="outline" />
                                </Button>
                              ) : (
                                <Button
                                  backgroundColor={"neutral-white"}
                                  p={"1"}
                                  onClick={() => {
                                    toggleWidgetVisibility(key);
                                  }}
                                >
                                  <Icon as="eyeOff" />
                                </Button>
                              )}
                            </Box>
                          )}
                        </Stack>
                        <WidgetDescription>
                          {resumeValues[key]?.description}
                        </WidgetDescription>
                      </Box>
                      <Stack
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <WidgetValue>
                          {resumeValues[key].content(state.resume[key]?.total)}
                        </WidgetValue>

                        <Whisper
                          placement="top"
                          trigger="hover"
                          speaker={
                            <Tooltip>
                              {t(
                                "porcentaje-de-cambio-respecto-al-periodo-anterior"
                              )}
                            </Tooltip>
                          }
                        >
                          {state.resume[key]?.variation >= 0 ? (
                            <WidgetVariation>
                              <Icon
                                as="arrowDropUp"
                                color="semantic-success-600"
                              />

                              <VariationNumber
                                number={state.resume[key]?.variation}
                              >
                                {state.resume[key]?.variation}%
                              </VariationNumber>
                            </WidgetVariation>
                          ) : (
                            <WidgetVariation>
                              <Icon
                                as="arrowDropDown"
                                color="semantic-error-600"
                              />

                              <VariationNumber
                                number={state.resume[key]?.variation}
                              >
                                {state.resume[key]?.variation}%
                              </VariationNumber>
                            </WidgetVariation>
                          )}
                        </Whisper>
                      </Stack>
                    </Widget>
                  )}
                </SortableItem>
              </Box>
            );
          })}
        </WidgetContainer>
      </Sortable>
    </Container>
  );
};
export default Resume;

const VariationNumber = styled.div<{ number: number }>`
  color: ${(props) => (props.number >= 0 ? "#07AD4B" : "#E22E2E")};
  font-size: 14px;
  font-weight: 600;
`;
const Sortable = styled(SortableList)`
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
`;
export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  width: 120px;
  justify-content: space-between;
`;

export const WidgetDescription = styled.div`
  color: var(--Neutral-700, #646464);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  padding: 5px 0px 10px 0px;
`;
export const WidgetTitle = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;

  color: #333;
`;
export const WidgetTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding-bottom: 5px;
`;
const WidgetValue = styled.div`
  color: var(--Neutral-800, #333);
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  bottom: 0;
`;
const WidgetVariation = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
`;
// export const Icon = styled.img``;
const Widget = styled.div<{ isvisible: boolean }>`
  height: 150px;

  background: #fff;
  border-radius: 8px;
  padding: 20px 15px 10px 15px;
  box-shadow: 0px 1px 3px 0px rgba(64, 67, 91, 0.1),
    0px 1px 2px 0px rgba(64, 67, 91, 0.06);
  display: ${(props) => (props.isvisible ? "flex" : "none")};
  flex-direction: column;
  justify-content: space-between;
`;
const WidgetContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 100%;
  margin-top: 20px;
`;

export const Line = styled.div`
  position: relative;
  width: 100%;
  height: 1px;
  background-color: #acaec4;
`;
export const VisibilityContainer = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #333;
`;
export const Title = styled.div`
  color: #333;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 120% */
`;
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0px 10px 0px;
`;

export const Container = styled(Box)`
  width: 100%;
  margin: 10px 0px 10px 0px;
  position: relative;
  padding: 0px 10px;

  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
`;
