import {
  WidgetTitleContainer,
  WidgetTitle,
  WidgetDescription,
} from "../Resume/Resume";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { SortableKnob } from "react-easy-sort";
import { colorPalette, formatDateToYYYYMMDD } from "../../../utils/formats";
import {
  ChartContainer,
  WidgetHeaderContainer,
  WidgetHeaderSubContainer,
} from "./Analysis";
import { useReorderProvider } from "../../../context/ReorderContext/provider";
import { useState } from "react";
import { useDataProvider } from "../../../context/provider";
import { styled } from "styled-components";
import { DataMessage } from "../EfficientPurchasing/EfficientPurchasing";
import { NumberUtils } from "../../../utils";
import {
  Box,
  Button,
  Icon,
  Skeleton,
  Stack,
  useGoogleAnalytics,
} from "@extendeal/vendors-design-system";
import { useTranslation } from "../../../hooks";
import { GTags } from "../../../models/gtags";

interface DrugData {
  date: string;
  drug_manufacturer_id: number;
  drug_manufacturer_name: string;
  total: number;
  total_sum: number;
}

interface ManufacturerData {
  name: string;
  data: number[];
}
const getUniqueDates = (data: DrugData[]) => {
  return Array.from(new Set(data.map((item) => item.date))).sort(
    (a, b) => new Date(a).getTime() - new Date(b).getTime()
  );
};
const getManufacturerDataByDate = (data: DrugData[]): ManufacturerData[] => {
  const uniqueDates = Array.from(new Set(data?.map((item) => item.date))).sort(
    (a, b) => new Date(a).getTime() - new Date(b).getTime()
  );

  const manufacturers = data.reduce(
    (acc: Record<string, Record<string, number>>, item) => {
      if (!acc[item.drug_manufacturer_name]) {
        acc[item.drug_manufacturer_name] = {};
      }
      acc[item.drug_manufacturer_name][item.date] = item.total;
      return acc;
    },
    {}
  );

  const result: ManufacturerData[] = Object.entries(manufacturers).map(
    ([name, records]) => {
      const data = uniqueDates.map((date) => records[date] || 0);
      return { name, data };
    }
  );

  return result;
};

export default function DailySpendingByDrugstore() {
  const { state, isLoading } = useDataProvider();
  const { isReordering, visibility, toggleWidgetVisibility } =
    useReorderProvider();
  const { t } = useTranslation();
  const { trackEvent } = useGoogleAnalytics();
  const [filter, setFilter] = useState("15");
  const uniqueDates = state.moneyPerDm
    ? getUniqueDates(state.moneyPerDm)
    : null;

  return (
    <>
      <WidgetHeaderContainer>
        <WidgetHeaderSubContainer>
          {" "}
          <WidgetTitleContainer>
            {isReordering && (
              <SortableKnob>
                <div
                  onClick={() => {
                    trackEvent(
                      GTags.ReorderTrackingEvents["Reorder-analysis"],
                      {
                        name: "Daily spending",
                      }
                    );
                  }}
                >
                  <Icon as="drag" color="neutral-600" />
                </div>
              </SortableKnob>
            )}
            <Box borderRadius={"md"} backgroundColor={"secondary-50"} p={"1"}>
              <Icon as="storeFront" type="outline" color="neutral-800" />
            </Box>
            <WidgetTitle>
              {t("monto-comprado-por-dia-por-drogueria")}
            </WidgetTitle>
          </WidgetTitleContainer>
          <WidgetDescription>
            {t("total-comprado-por-drogueria-por-dia")}
          </WidgetDescription>
        </WidgetHeaderSubContainer>
        <div style={{ display: "flex" }}>
          <WidgetHeaderSubContainer>
            {isReordering && (
              <Box>
                {" "}
                {visibility.money_per_dm ? (
                  <Button
                    backgroundColor={"neutral-white"}
                    p={"1"}
                    onClick={() => {
                      trackEvent(
                        GTags.ReorderTrackingEvents["Visibility-analysis"],
                        {
                          name: "Daily spending",
                        }
                      );
                      toggleWidgetVisibility("money_per_dm");
                    }}
                  >
                    <Icon as="eye" type="outline" />
                  </Button>
                ) : (
                  <Button
                    backgroundColor={"neutral-white"}
                    p={"1"}
                    onClick={() => toggleWidgetVisibility("money_per_dm")}
                  >
                    <Icon as="eyeOff" />
                  </Button>
                )}
              </Box>
            )}
          </WidgetHeaderSubContainer>
        </div>
      </WidgetHeaderContainer>
      {isLoading ? (
        <Stack alignItems={"center"} justifyContent="center">
          <Skeleton w={"100%"} h="400px" />
        </Stack>
      ) : (
        <ChartContainer>
          {state.moneyPerDm?.length ? (
            <HighchartsReact
              highcharts={Highcharts}
              options={{
                chart: {
                  type: "column",
                },
                title: {
                  text: ".",
                  style: {
                    color: "white",
                  },
                },
                tooltip: {
                  shared: true,
                  useHTML: true,
                  formatter: function () {
                    let total = 0;
                    this.points.forEach((point) => {
                      total += point.y;
                    });

                    let s = "<strong>" + this.points[0].key + "</strong><br/>"; // Usa la fecha real
                    this.points.forEach((point) => {
                      s +=
                        point.series.name +
                        ": <b>$ " +
                        Highcharts.numberFormat(point.y, 2) +
                        "</b><br/>";
                    });
                    s +=
                      "Total: <b>$ " +
                      Highcharts.numberFormat(total, 2) +
                      "</b>"; // Formatea el total correctamente

                    return s;
                  },
                },
                xAxis: {
                  categories: uniqueDates,
                  gridLineWidth: 1,
                  gridLineColor: "#e0e0e0",
                },
                yAxis: {
                  min: 0,
                  title: {
                    text: t("monto-gastado"),
                    style: {
                      color: "#333333",
                      fontSize: 14,
                      fontWeight: 600,
                    },
                  },
                  labels: {
                    style: {
                      fontSize: 12,
                    },
                  },
                  gridLineWidth: 1,
                  gridLineColor: "#e0e0e0",
                  gridLineDashStyle: "Dot",
                },
                legend: {
                  layout: "horizontal",
                  align: "center",
                  verticalAlign: "bottom",
                  itemMarginBottom: 5,
                  maxHeight: 90,
                  navigation: {
                    enabled: true,
                    arrowSize: 12,
                    style: {
                      fontWeight: "bold",
                      color: "#333",
                    },
                  },
                },

                colors: colorPalette,
                exporting: {
                  enabled: true,
                  filename: `${formatDateToYYYYMMDD(new Date())}_${t(
                    "monto-comprado-por-dia-por-drogueria"
                  )}`,
                  buttons: {
                    contextButton: {
                      menuItems: [
                        "viewFullscreen",
                        "printChart",
                        "downloadPNG",
                        "downloadJPEG",
                        "downloadPDF",
                        "downloadSVG",
                        "downloadXLS",
                      ],
                    },
                  },
                  csv: {
                    columnHeaderFormatter: (item, key) => {
                      return item.name || "Fecha";
                    },
                  },
                },
                plotOptions: {
                  column: {
                    stacking: "normal",
                    dataLabels: {
                      enabled: true,
                      inside: true,
                      color: "#FFFFFF",
                      style: {
                        textOutline: "none",
                      },
                      formatter: function () {
                        if (this.y !== 0) {
                          return NumberUtils.formatWithSuffix(this.y);
                        } else {
                          return null;
                        }
                      },
                    },
                  },
                },
                credits: {
                  enabled: false,
                },
                series: state.medicinalTop20
                  ? getManufacturerDataByDate(state.moneyPerDm)
                  : null,
              }}
            />
          ) : (
            <DataMessage>{t("no-se-encontraron-datos")}</DataMessage>
          )}
        </ChartContainer>
      )}
    </>
  );
}
