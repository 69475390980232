import { buildInternationalizationInstance } from "@extendeal/vendors-design-system";
import enUS from "./locales/en-US.json";
import esAr from "./locales/es-AR.json";
import esCol from "./locales/es-COL.json";
import esEs from "./locales/es-ES.json";
import esMx from "./locales/es-MX.json";
import ptBr from "./locales/pt-BR.json";

export default buildInternationalizationInstance({
  "en-US": enUS,
  "es-AR": esAr,
  "es-COL": esCol,
  "es-ES": esEs,
  "es-MX": esMx,
  "pt-BR": ptBr,
});
