namespace GTags {
  export enum DatePickerTrackingEvents {
    "DatePicker-on-change" = "datePicker-on-change",
    "DatePicker-shortcut-click" = "datePicker-shortcut-click",
  }
  export enum POSSelectPicketTrackingEvents {
    "PosSelectPicket-on-change" = "posSelectPicket-on-change",
    "PosSelectPicket-clean" = "posSelectPicket-clean",
    "PosSelectPicket-select-all" = "posSelectPicket-select-all",
    "PosSelectPicket-apply" = "posSelectPicket-apply",
  }
  export enum ReorderTrackingEvents {
    "Reorder-button" = "reorder-button",
    "Reorder-cancel-button" = "reorder-cancel-button",
    "Reorder-save-button" = "reorder-save-button",
    "Reorder-summary" = "reorder-summary",
    "Visibility-summary" = "visibility-summary",
    "Reorder-analysis" = "reorder-analysis",
    "Visibility-analysis" = "visibility-analysis",
    "Reorder-efficient-purchase" = "reorder-efficient-purchase",
    "Visibility-efficient-purchase" = "visibility-efficient-purchase",
  }
  export enum PurchaseAnalysisEvents {
    "PurchaseAnalysis-download-file-button" = "purchaseAnalysis-download-file-button",
  }
  export enum EfficientPurchaseEvents {
    "EfficientPurchase-download-file-button" = "efficientPurchase-download-file-button",
  }
}
export { GTags };
