import { SortableKnob } from "react-easy-sort";
import { useReorderProvider } from "../../../context/ReorderContext/provider";

import {
  WidgetTitleContainer,
  WidgetTitle,
  WidgetDescription,
} from "../Resume/Resume";
import { formatDateToYYYYMMDD } from "../../../utils/formats";
import {
  ChartContainer,
  WidgetHeaderContainer,
  WidgetHeaderSubContainer,
} from "./Analysis";

import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { DataMessage } from "../EfficientPurchasing/EfficientPurchasing";
import { useDataProvider } from "../../../context/provider";
import {
  Box,
  Button,
  Icon,
  Skeleton,
  Stack,
  useGoogleAnalytics,
} from "@extendeal/vendors-design-system";
import { useTranslation } from "../../../hooks";
import { GTags } from "../../../models/gtags";

export default function TopMedicinalProducts() {
  const { isReordering, visibility, toggleWidgetVisibility } =
    useReorderProvider();
  const { state, isLoading } = useDataProvider();
  const { t } = useTranslation();
  const { trackEvent } = useGoogleAnalytics();
  const medicinalCategories = state.medicinalTop20?.map(
    (value) => value.description
  );
  const medicinalQuantity = state.medicinalTop20?.map(
    (value) => value.quantity
  );

  return (
    <>
      <WidgetHeaderContainer>
        <WidgetHeaderSubContainer>
          <WidgetTitleContainer>
            {isReordering && (
              <SortableKnob>
                <div
                  onClick={() => {
                    trackEvent(
                      GTags.ReorderTrackingEvents["Reorder-analysis"],
                      {
                        name: "Top medicinal",
                      }
                    );
                  }}
                >
                  <Icon as="drag" color="neutral-600" />
                </div>
              </SortableKnob>
            )}
            <Box borderRadius={"md"} backgroundColor={"secondary-50"} p={"1"}>
              <Icon as="pharmacy" type="outline" color="neutral-800" />
            </Box>
            <WidgetTitle>{t("top-medicamentos-mas-comprados")}</WidgetTitle>
          </WidgetTitleContainer>

          <WidgetDescription>
            {t("suma-de-la-cantidad-de-veces-que-se-compro-cada-producto")}
          </WidgetDescription>
        </WidgetHeaderSubContainer>
        <WidgetHeaderSubContainer style={{ display: "flex" }}>
          {isReordering && (
            <Box>
              {" "}
              {visibility.top_n_meds ? (
                <Button
                  backgroundColor={"neutral-white"}
                  p={"1"}
                  onClick={() => {
                    trackEvent(
                      GTags.ReorderTrackingEvents["Visibility-analysis"],
                      {
                        name: "Top medicinal",
                      }
                    );
                    toggleWidgetVisibility("top_n_meds");
                  }}
                >
                  <Icon as="eye" type="outline" />
                </Button>
              ) : (
                <Button
                  backgroundColor={"neutral-white"}
                  p={"1"}
                  onClick={() => toggleWidgetVisibility("top_n_meds")}
                >
                  <Icon as="eyeOff" />
                </Button>
              )}
            </Box>
          )}
        </WidgetHeaderSubContainer>
      </WidgetHeaderContainer>
      {isLoading ? (
        <Stack alignItems={"center"} justifyContent="center">
          <Skeleton w={"100%"} h="400px" />
        </Stack>
      ) : (
        <ChartContainer>
          {state.medicinalTop20?.length ? (
            <HighchartsReact
              highcharts={Highcharts}
              options={{
                chart: {
                  type: "bar",
                },
                title: {
                  text: ".",
                  style: {
                    color: "white",
                  },
                },
                xAxis: {
                  categories: medicinalCategories,
                  gridLineWidth: 1,
                  gridLineColor: "#e0e0e0",
                  gridLineDashStyle: "Dot",
                },
                yAxis: {
                  title: {
                    text: "",
                  },
                  tickInterval: 1,
                },
                plotOptions: {
                  bar: {
                    dataLabels: {
                      enabled: true,
                    },
                  },
                },
                exporting: {
                  enabled: true,
                  filename: `${formatDateToYYYYMMDD(new Date())}_${t(
                    "top-medicamentos-mas-comprados"
                  )}`,
                  buttons: {
                    contextButton: {
                      menuItems: [
                        "viewFullscreen",
                        "printChart",
                        "downloadPNG",
                        "downloadJPEG",
                        "downloadPDF",
                        "downloadSVG",
                        "downloadXLS",
                      ],
                    },
                  },
                  csv: {
                    columnHeaderFormatter: (item, key) => {
                      return item.name || "Nombre del producto";
                    },
                  },
                },

                series: [
                  {
                    name: t("cantidad-de-productos"),
                    data: medicinalQuantity,
                    color: "#0084FF",
                  },
                ],
                credits: {
                  enabled: false,
                },
              }}
            />
          ) : (
            <DataMessage>{t("no-se-encontraron-datos")}</DataMessage>
          )}
        </ChartContainer>
      )}
    </>
  );
}
