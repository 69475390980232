import esES from "rsuite/esm/locales/es_ES";
import esAR from "rsuite/esm/locales/es_AR";
import enUS from "rsuite/esm/locales/en_US";
import ptBR from "rsuite/esm/locales/pt_BR";
import { DateRangePickerLocale as RSuiteDateRangePickerLocale } from "rsuite";

namespace NumberUtils {
  export const formatWithSuffix = (num: number): string => {
    if (num >= 1e9) {
      return (num / 1e9).toFixed(1).replace(/\.0$/, "") + "B";
    }
    if (num >= 1e6) {
      return (num / 1e6).toFixed(1).replace(/\.0$/, "") + "M";
    }
    if (num >= 1e3) {
      return (num / 1e3).toFixed(1).replace(/\.0$/, "") + "K";
    }
    return num.toString();
  };
}

namespace LanguageUtils {
  export enum SupportedLanguages {
    EsAr = "es-AR",
    EsMx = "es-MX",
    EsCol = "es-COL",
    EsEs = "es-ES",
    PtBr = "pt-BR",
    EnUs = "en-US",
  }

  export const getLanguageFromCountry = (
    countryCode: string
  ): SupportedLanguages => {
    const languageMap: Record<string, SupportedLanguages> = {
      AR: SupportedLanguages.EsAr,
      MX: SupportedLanguages.EsMx,
      COL: SupportedLanguages.EsCol,
      ES: SupportedLanguages.EsEs,
      BR: SupportedLanguages.PtBr,
      US: SupportedLanguages.EnUs,
    };

    return languageMap[countryCode] || SupportedLanguages.EsMx;
  };

  export const getLocaleConfigForHighcharts = (
    lang: SupportedLanguages
  ): { decimalPoint: string; thousandsSep: string } => {
    const locales: Record<
      SupportedLanguages,
      { decimalPoint: string; thousandsSep: string }
    > = {
      [SupportedLanguages.EsAr]: { decimalPoint: ",", thousandsSep: "." },
      [SupportedLanguages.EsMx]: { decimalPoint: ".", thousandsSep: "," },
      [SupportedLanguages.EsCol]: { decimalPoint: ",", thousandsSep: "." },
      [SupportedLanguages.PtBr]: { decimalPoint: ",", thousandsSep: "." },
      [SupportedLanguages.EnUs]: { decimalPoint: ".", thousandsSep: "," },
      [SupportedLanguages.EsEs]: { decimalPoint: ",", thousandsSep: "." },
    };

    return locales[lang] || locales[SupportedLanguages.EsMx];
  };

  export const LanguageContextMap: Record<SupportedLanguages, typeof esAR> = {
    [SupportedLanguages.EsAr]: esAR,
    [SupportedLanguages.EsMx]: esES,
    [SupportedLanguages.EsCol]: esES,
    [SupportedLanguages.EsEs]: esES,
    [SupportedLanguages.PtBr]: ptBR,
    [SupportedLanguages.EnUs]: enUS,
  };
  type DateFormat = {
    formattedMonthPattern: string;
    formattedDayPattern: string;
    formattedDatePattern: string;
  };
  const DateFormats: Record<"latam" | "us", DateFormat> = {
    latam: {
      formattedMonthPattern: "MMMM yyyy",
      formattedDayPattern: "EEEE",
      formattedDatePattern: "dd/MM/yyyy",
    },
    us: {
      formattedMonthPattern: "MMMM yyyy",
      formattedDayPattern: "EEEE",
      formattedDatePattern: "MM/dd/yyyy",
    },
  };

  type DateRangePickerLocale = Partial<RSuiteDateRangePickerLocale> & {
    formattedDatePattern: string;
  };

  export const DateRangePickerLocales: Record<
    SupportedLanguages,
    DateRangePickerLocale
  > = {
    [SupportedLanguages.EsAr]: {
      ...LanguageContextMap["es-AR"].DateRangePicker,
      ...DateFormats.latam,
    },
    [SupportedLanguages.EsMx]: {
      ...LanguageContextMap["es-MX"].DateRangePicker,
      ...DateFormats.latam,
    },
    [SupportedLanguages.EsCol]: {
      ...LanguageContextMap["es-COL"].DateRangePicker,
      ...DateFormats.latam,
    },
    [SupportedLanguages.EsEs]: {
      ...LanguageContextMap["es-ES"].DateRangePicker,
      ...DateFormats.latam,
    },
    [SupportedLanguages.PtBr]: {
      ...LanguageContextMap["pt-BR"].DateRangePicker,
      ...DateFormats.latam,
    },
    [SupportedLanguages.EnUs]: {
      ...LanguageContextMap["en-US"].DateRangePicker,
      ...DateFormats.us,
    },
  };
}
export { NumberUtils, LanguageUtils };
