import { ReorderProvider } from "./context/ReorderContext/provider";
import { useEffect, useState } from "react";
import { useDataProvider } from "./context/provider";
import Main from "./components/templates/Main";
import Searchbar from "./components/templates/Searchbar";
import { x } from "@xstyled/styled-components";
import "../src/utils/highcharts-config";
import useUserProfileApi from "./hooks/api/useUserProfileApi";
import { useTranslation } from "./hooks";
import { LanguageUtils } from "./utils";
import Highcharts from "highcharts";

export const navbarHeight = "76px";
function App() {
  const { getPointsOfSale, pointsOfSale, isEmpty } = useDataProvider();
  const { getUserLocation } = useUserProfileApi();
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState<
    LanguageUtils.SupportedLanguages | undefined
  >();
  const fetchUserProfileLocation = async () => {
    const response = await getUserLocation();
    if (!response) return;
    const userLanguage = LanguageUtils.getLanguageFromCountry(
      response.data.client.country_alfa_2
    );
    setLanguage(userLanguage);

    i18n.changeLanguage(userLanguage);
  };
  useEffect(() => {
    getPointsOfSale();
    fetchUserProfileLocation();
  }, [language]);

  useEffect(() => {
    const localeConfig = LanguageUtils.getLocaleConfigForHighcharts(language);

    Highcharts.setOptions({
      lang: {
        viewFullscreen: t("ver-en-pantalla-completa"),
        printChart: t("imprimir-grafico"),
        downloadPNG: t("descargar-imagen-png"),
        downloadJPEG: t("descargar-imagen-jpeg"),
        downloadPDF: t("descargar-archivo-pdf"),
        downloadSVG: t("descargar-imagen-svg"),
        downloadCSV: t("descargar-csv"),
        downloadXLS: t("descargar-archivo-xls"),
        viewData: t("ver-tabla-de-datos"),
        decimalPoint: localeConfig.decimalPoint,
        thousandsSep: localeConfig.thousandsSep,
      },
    });
  }, [language]);
  return (
    <ReorderProvider>
      <x.div
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        minH={`calc(100vh - ${navbarHeight})`}
        backgroundColor={"#f0f5ff"}
      >
        <x.div w={"full"} maxW="1280px"></x.div>
        {pointsOfSale && language ? (
          <>
            <Searchbar language={language} />
            <Main />
          </>
        ) : null}
      </x.div>
    </ReorderProvider>
  );
}

export default App;
