import {
  WidgetTitleContainer,
  WidgetTitle,
  WidgetDescription,
} from "../Resume/Resume";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { SortableKnob } from "react-easy-sort";
import { colorPalette, formatDateToYYYYMMDD } from "../../../utils/formats";
import { ChartContainer } from "./Analysis";
import { useReorderProvider } from "../../../context/ReorderContext/provider";
import { useDataProvider } from "../../../context/provider";
import { styled } from "styled-components";
import { DataMessage } from "../EfficientPurchasing/EfficientPurchasing";
import {
  Box,
  Button,
  Icon,
  Skeleton,
  Stack,
  useGoogleAnalytics,
} from "@extendeal/vendors-design-system";
import { useTranslation } from "../../../hooks";
import { GTags } from "../../../models/gtags";

export default function DrugstorePurchaseDistribution() {
  const { state, isLoading } = useDataProvider();
  const { isReordering, visibility, toggleWidgetVisibility } =
    useReorderProvider();
  const { t } = useTranslation();
  const { trackEvent } = useGoogleAnalytics();
  const chartD = state.drugDistribution
    ? state.drugDistribution.map((value) => {
        return {
          name: value.drug_manufacturer_name,
          y: value.total,
        };
      })
    : null;

  return (
    <>
      <WidgetHeadContainer>
        <WidgetTitleContainer>
          {isReordering && (
            <SortableKnob>
              <div
                onClick={() => {
                  trackEvent(GTags.ReorderTrackingEvents["Reorder-analysis"], {
                    name: "Drugstore purchase distribution",
                  });
                }}
              >
                <Icon as="drag" color="neutral-600" />
              </div>
            </SortableKnob>
          )}
          <Box borderRadius={"md"} backgroundColor={"secondary-50"} p={"1"}>
            <Icon as="storeFront" type="outline" color="neutral-800" />
          </Box>
          <WidgetTitle>{t("distribucion-de-compra-por-drogueria")}</WidgetTitle>
        </WidgetTitleContainer>
        {isReordering && (
          <Box>
            {" "}
            {visibility.distrib_purchase_per_dm ? (
              <Button
                backgroundColor={"neutral-white"}
                p={"1"}
                onClick={() =>
                  toggleWidgetVisibility("distrib_purchase_per_dm")
                }
              >
                <Icon as="eye" type="outline" />
              </Button>
            ) : (
              <Button
                backgroundColor={"neutral-white"}
                p={"1"}
                onClick={() => {
                  trackEvent(
                    GTags.ReorderTrackingEvents["Visibility-analysis"],
                    {
                      name: "Drugstore purchase distribution",
                    }
                  );
                  toggleWidgetVisibility("distrib_purchase_per_dm");
                }}
              >
                <Icon as="eyeOff" />
              </Button>
            )}
          </Box>
        )}
      </WidgetHeadContainer>
      <WidgetDescription>
        {t("monto-total-comprado-a-cada-drogueria")}
      </WidgetDescription>
      {isLoading ? (
        <Stack alignItems={"center"} justifyContent="center">
          <Skeleton w={"100%"} h="400px" />
        </Stack>
      ) : (
        <ChartContainer>
          {state.drugDistribution?.length ? (
            <HighchartsReact
              highcharts={Highcharts}
              options={{
                chart: {
                  type: "pie",
                },
                title: {
                  text: ".",
                  style: {
                    color: "white",
                  },
                },
                colors: colorPalette,
                tooltip: {
                  pointFormat: "{series.name}: <b>$ {point.y:,.0f}</b>",
                },
                plotOptions: {
                  pie: {
                    innerSize: "70%",
                    cursor: "pointer",
                    dataLabels: {
                      enabled: true,
                      borderRadius: 5,
                      format: "{point.name}: {point.percentage:.1f} %",
                    },
                  },
                },
                exporting: {
                  enabled: true,
                  filename: `${formatDateToYYYYMMDD(new Date())}_${t(
                    "distribucion-de-compra-por-drogueria"
                  )}`,
                  buttons: {
                    contextButton: {
                      menuItems: [
                        "viewFullscreen",
                        "printChart",
                        "downloadPNG",
                        "downloadJPEG",
                        "downloadPDF",
                        "downloadSVG",
                        "downloadXLS",
                      ],
                    },
                  },
                  csv: {
                    columnHeaderFormatter: (item, key) => {
                      if (!item) {
                        return "Droguería";
                      }
                      return item.name;
                    },
                  },
                },
                credits: {
                  enabled: false,
                },
                series: [
                  {
                    name: t("compra-efectuada"),
                    data: chartD,
                  },
                ],
              }}
            />
          ) : (
            <>
              <DataMessage>{t("no-se-encontraron-datos")}</DataMessage>
            </>
          )}
        </ChartContainer>
      )}
    </>
  );
}
const WidgetHeadContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
